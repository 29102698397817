<template>
  <div></div>
</template>

<script>
import axios from 'axios'
import { onMounted, ref  } from 'vue'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const session_id = ref('')

    const loadScript = () => {
      console.log(process.env.VUE_APP_GOOGLE_TOKEN);
      const lang = route.query.lang
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      if(lang){
        script.src = `https://www.google.com/maps/api/js/reviews?key=${process.env.VUE_APP_GOOGLE_TOKEN}&language=${lang}`
      }
      else{
        script.src = `https://www.google.com/maps/api/js/reviews?key=${process.env.VUE_APP_GOOGLE_TOKEN}`
      }
      script.type = 'text/javascript'
      document.head.appendChild(script)
      
      //wait til window is finish loading
      window.addEventListener('load', function() {
        loadReview(onLoad)
      })
    }
    const createReview = () => {
      const rating = route.query.rating  ? Number(route.query.rating) : 0
      const text   = route.query.message || ' ';
      const publishReviewOptions = {}

      publishReviewOptions.placeId = route.params.place_id
      
      if(rating <= 5 && rating >= 0 && Number.isInteger(rating))
        publishReviewOptions.initialRating = rating
      

      publishReviewOptions.initialReviewText = text

      const googlePublishReviewFlow = window.google.places.reviews.createPublishReviewFlow(publishReviewOptions)

      googlePublishReviewFlow.addListener('complete', function() {
        whenPublish()
      })
    }

    const loadReview = (callback) => {
      createReview()
      callback()
    }

    const onLoad = async () => {
      const isInIframe = (parent !== window) //check that the page is in an iframe
      let domain = null;
      if (isInIframe)
        domain = document.referrer

      const token = process.env.VUE_APP_CUSTPLACE_API_TOKEN
      const data = {
        place_id: route.params.place_id,
        domain: domain
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      try {
        const res = await axios.post('https://apis.custplace.com/v3/grw', data, config)
        session_id.value = res.data.session_id
      }
      catch(error) {
        console.log(error)
      }
    }

    const whenPublish = async () => {
      const token = '4|o6tay6EnRjAa3wJAgRJByxObgUx3XNlw8YqaBbSF'
      const data = {
        session_id: session_id.value
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      try {
        await axios.post('https://apis.custplace.com/v3/grw/published', data, config)
        window.parent.postMessage('GoogleReviewPublished')
      }
      catch(error) {
        console.log(error)
      }
    }

    onMounted(loadScript)

    return {
      
    }
  }
}
</script>
